import { graphql } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import PageLayout from "../components/PageLayout";
import AcceptableUsePolicy from "../../static/pdfs/Acceptable_Use_Policy.pdf";
import {
  TITLE_FONTSIZE,
  SUBTITLE_FONTSIZE,
  TEXT_FONTSIZE,
  TERMS,
} from "../utilities/constants";
import SwitchButton from "../components/SwitchButton";
import { constants } from "../redux/constants";
import { useDispatch } from "react-redux";
import { saveRouteDetails } from "../redux/actions/routeActions";

const AREA_WIDTH = "45%";
const MyContainer = styled.div`
  padding: 10%;
  background-color: white;
`;

const TitleArea = styled.div`
  display: flex;
  margin: auto auto 20px auto;
  width: 45rem;
  justify-content: space-between;
  @media (max-width: 805px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: ${TITLE_FONTSIZE};
  font-weight: 600;

  @media (max-width: 450px) {
    font-size: 30px;
  }
  @media (max-width: 380px) {
    font-size: 25px;
  }
`;

const TermSelectArea = styled.div`
  margin-bottom: 30px;
`;

const SelectBox = styled.div`
  background-color: #ecfaff;
  border-radius: 10px;
  width: ${AREA_WIDTH};
  padding: 1rem;
  color: #0494ca;
  font-weight: 500;
  cursor: pointer;
  font-size: ${SUBTITLE_FONTSIZE};
  & ~ & {
    margin-top: 20px;
  }
`;

const TermDetail = styled.div`
  max-width: 1300px;
  margin: 30px auto auto;
  font-size: ${TEXT_FONTSIZE};
  font-weight: 400;
  p {
    font-size: ${TEXT_FONTSIZE};
    font-weight: 400;
  }
  * {
    text-align: justify;
  }
`;
const DetailTitle = styled.div`
  color: #0494ca;
  font-weight: 500;
  font-size: ${SUBTITLE_FONTSIZE};
  margin-bottom: 20px;
`;
const DetailDescription = styled.div``;
const DetailSubtitle = styled.div`
  font-size: ${SUBTITLE_FONTSIZE};
  font-weight: 600;
  margin-bottom: 5px;
`;

const DetailPdf = styled.a`
  color: #0494ca;
  text-decoration: underline !important;
`;

const DetailContent = styled.div`
  margin-top: 2%;
  whitespace: "pre-wrap";
`;

const DetailParagraph = styled.div`
  min-height: 5px;
`;

const DetailParagraphBold = styled.div`
  min-height: 5px;
  font-weight: 600;
`;

const TermConditionHeading2 = styled.h2`
  text-align: center;
  margin: 30px auto;
  border-top: 2px black solid;
  border-bottom: 2px black solid;
  padding: 8px 0;
`;

const AcceptableUsePolicyHeading = styled.h2`
  font-size: ${TITLE_FONTSIZE};
  font-weight: 600;
  text-align: center;
  padding: 30px 0 5px 0;
  margin-top: 50px;
  @media (max-width: 450px) {
    font-size: 30px;
  }
  @media (max-width: 380px) {
    font-size: 25px;
  }
`;

const formatString = (text: string) => {
  const stringList = text.split("\n");
  return (
    <>
      {stringList.map((s, i) => {
        if (s.includes("https")) {
          let length = s.length;
          let linkIndex = s.indexOf("https");
          let periodIndex = length - 1;
          let firstPart = s.substring(0, linkIndex);
          let link = s.substring(linkIndex, periodIndex);
          let linkText = link;
          // check for crtc website.
          if (link.includes("crtc.gc.ca")) {
            linkText = "CRTC's website";
          }
          let lastPart = s.substring(periodIndex, length);
          return (
            <DetailParagraph key={i}>
              <Trans>{firstPart}</Trans>
              <a href={link}>{linkText}</a>
              <Trans>{lastPart}</Trans>
            </DetailParagraph>
          );
        } else if (s.trim() === "<b>Cancellation and Refund Policy</b>") {
          let boldedContent = s.substring(3, s.length - 4);
          return (
            <DetailParagraphBold key={i} id="cancellationAndRefundPolicy">
              <Trans>{boldedContent}</Trans>
            </DetailParagraphBold>
          );
        } else if (s.startsWith("<b>") && s.endsWith("</b>")) {
          let boldedContent = s.substring(3, s.length - 4);
          return (
            <DetailParagraphBold key={i}>
              <Trans>{boldedContent}</Trans>
            </DetailParagraphBold>
          );
        }
        return (
          <DetailParagraph key={i}>
            <Trans>{s}</Trans>
          </DetailParagraph>
        );
      })}
    </>
  );
};

const DEFAULT_COUNTRY = "Canada";
const SWITCHED_COUNTRY = "US";
const DEFAULT_TERM_ID = 0;

const TermOfService = () => {
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [termId, setTermId] = useState(DEFAULT_TERM_ID);

  const privacyPolicyRef = useRef(null);

  const dispatch = useDispatch();

  const fromSectionLinkHandle = (country, section) => {
    if (country && country.toLowerCase() === constants.USA.toLowerCase()) {
      console.log(country);
      setCountry(SWITCHED_COUNTRY);
    }

    let content;

    if (section) {
      setTimeout(() => {
        // cancellation and refund policy link
        if (section.toLowerCase() === "early-cancellation") {
          content = document.getElementById("cancellationAndRefundPolicy");

          // privacy policy link
        } else if (section.toLowerCase() === "privacy-policy") {
          content = document.getElementById("privacyPolicy");
        }
        let y = content.getBoundingClientRect().top + window.pageYOffset - 165;
        window.scrollTo({ top: y, behavior: "smooth" });
      }, 100);
    }

    window.history.pushState("object or string", "Title", "/terms-of-service");
  };

  useEffect(() => {
    dispatch(saveRouteDetails("terms-of-service") as any);

    const section = new URLSearchParams(window.location.search).get("section");

    const countryParam = new URLSearchParams(window.location.search).get(
      "country"
    );

    if (countryParam || section) {
      fromSectionLinkHandle(countryParam, section);
    }
  }, []);

  const onSwitchCountry = () =>
    country === DEFAULT_COUNTRY
      ? setCountry(SWITCHED_COUNTRY)
      : setCountry(DEFAULT_COUNTRY);

  return (
    <PageLayout backgroundColor="#f2fafc">
      <title>Terms of Service | PhoneBox</title>
      <MyContainer>
        <TitleArea>
          <Title>
            <Trans>{"Terms of Service - " + country}</Trans>
          </Title>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <SwitchButton
              width={120}
              height={60}
              imgOn="/images/shop/CanadaFlag.png"
              imgOff="/images/shop/us.png"
              textOn="USA"
              textOff="CAN"
              onSwitch={onSwitchCountry}
              defaultState={country === "Canada" ? true : false}
            />
          </div>
        </TitleArea>
        <TermDetail>
          {country === "US" && (
            <>
              <DetailDescription>
                {TERMS.find((x) => x.country == country).description.map(
                  (d, i) => (
                    <div key={i}>{formatString(d)}</div>
                  )
                )}
              </DetailDescription>
              {TERMS.find((x) => x.country == country)?.content.map((t, i) => {
                return (
                  <DetailContent key={i}>
                    {t.title === "Privacy Policy" ? (
                      <DetailSubtitle id="privacyPolicy" ref={privacyPolicyRef}>
                        <Trans>{t.title}</Trans>
                      </DetailSubtitle>
                    ) : (
                      <DetailSubtitle>
                        <Trans>{t.title}</Trans>
                      </DetailSubtitle>
                    )}
                    <DetailDescription>
                      <div>
                        <Trans>{formatString(t.text)}</Trans>
                      </div>
                    </DetailDescription>

                    {t.title === "Changes to This Privacy Statement" && (
                      <DetailPdf href={AcceptableUsePolicy} download>
                        <Trans>Acceptable Use Policy</Trans>
                      </DetailPdf>
                    )}
                  </DetailContent>
                );
              })}
            </>
          )}
          {country === constants.CANADA && (
            <>
              <ol>
                <TermConditionHeading2>GENERAL</TermConditionHeading2>
                <li>
                  <p>
                    <strong>
                      What are the Terms and Conditions of Service?
                    </strong>
                  </p>
                  <p>
                    These terms and conditions of service (“Service Terms”)
                    govern your use of any Services, as defined below.
                  </p>
                  <p>Throughout this document:</p>
                  <ul>
                    <li>
                      <strong>“Agreement”</strong> has the meaning set out in
                      Section 2;
                    </li>
                    <li>
                      <strong>“Affiliate”</strong> has the meaning set out in
                      the BCBCA, and also includes any partnership or other
                      unincorporated association in which a PhoneBox or any of
                      its affiliated bodies corporate (as so defined in the
                      BCBCA) that has a controlling interest or by which it is
                      controlled;
                    </li>
                    <li>
                      <strong>“BCBCA”</strong> means British Columbia&apos;s
                      Business Corporations Act, SBC 2002, c 57;
                    </li>
                    <li>
                      <strong>“CCTS”</strong> has the meaning in Section 41;
                    </li>
                    <li>
                      <strong>“CRTC”</strong> has the meaning in Section 41;
                    </li>
                    <li>
                      <strong>“Cancellation Charges”</strong> are defined in
                      Section 37;
                    </li>
                    <li>
                      <strong>“Customer Accessed Content”</strong> has the
                      meaning in Section 23;
                    </li>
                    <li>
                      <strong>“Customer Provided Content”</strong> has the
                      meaning in Section 24;
                    </li>
                    <li>
                      <strong>“Device”</strong> means any mobile wireless
                      enabled device that is used in conjunction with Services;
                    </li>
                    <li>
                      <strong>“E911”</strong> means enhanced 9-1-1 service which
                      may provide emergency services personnel with your
                      location information and phone number;
                    </li>
                    <li>
                      <strong>“Equipment”</strong> means any device, equipment
                      or hardware used to access the Services or used in
                      conjunction with the Services;
                    </li>
                    <li>
                      <strong>“Fees”</strong> has the meaning in Section 7;
                    </li>
                    <li>
                      <strong>“Fixed Term”</strong> means a minimum contract
                      period for a particular Service;
                    </li>
                    <li>
                      <strong>“I”</strong>, <strong>“me”</strong>,
                      <strong>“you”</strong>, <strong>“your”</strong> and
                      <strong>“yours”</strong> refer to you but also to persons
                      that you authorize to use the Service or act as your agent
                      with regard to the Service;
                    </li>
                    <li>
                      <strong>“Identifiers”</strong> means e-mail addresses,
                      phone numbers, account numbers, personal identification
                      numbers (<strong>“PINs”</strong>), Internet Protocol
                      addresses, personal web page addresses, access codes and
                      any other identifier assigned to you by PhoneBox;
                    </li>
                    <li>
                      <strong>“Mobile Services”</strong> refers to
                      PhoneBox&apos;s mobile wireless voice, data and text
                      Services;
                    </li>
                    <li>
                      <strong>“PhoneBox”</strong> means Connex Global
                      Communications Inc. o/a PhoneBox and its Affiliates;
                    </li>
                    <li>
                      <strong>“PhoneBox Parties”</strong> means PhoneBox, its
                      partners, licensors, dealers, representatives, suppliers
                      and agents (and their respective employees, officers,
                      directors, shareholders and representatives);
                    </li>
                    <li>
                      <strong>“Late Payment Charges”</strong> has the meaning in
                      Section 9;
                    </li>
                    <li>
                      <strong>“My Account Portal”</strong> is a customer portal
                      on the Website that allows you to access features and
                      information relating to your Services and your account
                      with PhoneBox;
                    </li>
                    <li>
                      <strong>“NSF”</strong> has the meaning in Section 9;
                    </li>
                    <li>
                      <strong>“Policies”</strong> are defined in Section 11 of
                      the Service Terms;
                    </li>
                    <li>
                      <strong>“Residents of Québec”</strong> means residents of
                      Québec who enter into an Agreement to which the Consumer
                      Protection Act (Québec) applies;
                    </li>
                    <li>
                      <strong>“Service Agreement”</strong> means an agreement
                      setting out the terms for specific Services. Service
                      Agreements are available on the Website at the following
                      URL: https://gophonebox.com/terms-of-service) or otherwise
                      made available to you on request or when you with to order
                      Services;
                    </li>
                    <li>
                      <strong>“Services”</strong> mean any services (inclusive
                      of all Equipment and Software, as defined herein) that you
                      subscribe to or receive through PhoneBox, including but
                      not limited to wireline Internet and mobile wireless
                      voice, data and SMS/text;
                    </li>
                    <li>
                      <strong>“Service Terms”</strong> means these PhoneBox
                      Terms and Conditions of Service;
                    </li>
                    <li>
                      <strong>“Software”</strong> means any software used to
                      access the Services or used in conjunction with the
                      Services;
                    </li>
                    <li>
                      <strong>“Term”</strong> has the meaning in Section 4;
                    </li>
                    <li>
                      <strong>“Software Documents”</strong> has the meaning in
                      Section 21;
                    </li>
                    <li>
                      <strong>“us”</strong>, <strong>“we”</strong>,
                      <strong>“our”</strong> or <strong>“ourselves”</strong>
                      means PhoneBox;
                    </li>
                    <li>
                      <strong>“Website”</strong> means all content on the
                      https://gophonebox.com/terms-of-service domain; and
                    </li>
                    <li>
                      <strong>“Wi-Fi Calling”</strong> is a functionality that
                      is available with certain mobile wireless services offered
                      by PhoneBox that allows you to make and receive voice
                      calls and text messages on your mobile device over a Wi-Fi
                      connection.
                    </li>
                  </ul>
                  <br />
                </li>

                <li>
                  <p>
                    <strong>
                      What is included as part of my Agreement for Services with
                      PhoneBox?
                    </strong>
                  </p>
                  <p>
                    The <strong>“Agreement”</strong> includes the Service Terms,
                    Service Agreements for Services to which you subscribe, the
                    Critical Information Summary, any PhoneBox document
                    describing features, products or services and any other
                    document incorporated by reference together with these
                    Service Terms. In the event of an inconsistency between the
                    constituent documents of the Agreement, the inconsistency
                    will be resolved by giving preference first to any Service
                    Agreement(s), then to the Service Terms, then to any other
                    PhoneBox documentation describing features, products or
                    services, and lastly to any other documents incorporated by
                    reference.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>How do I accept the Agreement?</strong>
                  </p>
                  <div>
                    <strong>Not applicable to Residents of Québec:</strong>
                    <p>
                      You acknowledge that you have read, understood and agreed
                      to the Agreement by either: (i) placing an order for
                      Services by any means including online, over the phone or
                      in person at one of our sales centres or kiosks; or (ii)
                      using the Services.
                    </p>
                    <p>
                      If you do not agree to the Service Terms or the applicable
                      Service Agreement(s), you may not use the Services.
                    </p>
                    <strong>Applicable only to Residents of Québec:</strong>
                    <p>
                      When placing an order for Services by any means including
                      online, over the phone or in person at one of our sales
                      centres or kiosks, you will be presented with and asked to
                      agree to the Agreement.
                    </p>
                    <p>
                      If you do not agree to the Service Terms or the applicable
                      Service Agreement(s), you may not use the Services.
                    </p>
                  </div>
                </li>
                <li>
                  <p>
                    <strong>How long does the Agreement last?</strong>
                  </p>
                  <p>
                    The “Term” of your Agreement with PhoneBox begins when you
                    accept the Agreement and will continue until the Agreement
                    is terminated. PhoneBox provides Services to you on an
                    ongoing month-to-month basis unless a Fixed Term is set by a
                    Service Agreement. If a Fixed Term expires, Services will
                    continue to be provided to you on a month-to-month basis,
                    unless you cancel the applicable Service in accordance with
                    Section 33.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Can PhoneBox change the Agreement?</strong>
                  </p>
                  <p>
                    Unless otherwise specified in the Agreement, PhoneBox can
                    unilaterally change: (a) any aspect of a Service Agreement
                    for month-to-month Services; and (b) any aspect of a Service
                    Agreement for Fixed Term Services with the exception of
                    price, Term, the nature of the Services or any other key,
                    material or essential element of the Service Agreement.
                    Before we make any changes to the Agreement, we will give
                    you at least thirty (30) days&apos; written notice by email,
                    letter, bill insert or by posting the notice on our Website.
                    This notice will clearly identify the new or amended term or
                    provision, the former term as it read before (if
                    applicable), the date the amendment will come into force and
                    your rights. If you continue to use the Service after thirty
                    (30) days from the effective date indicated in the notice,
                    the new service term will become effective.
                  </p>
                  <strong>Applicable only to Residents of Québec:</strong>
                  <p>
                    If the change increases your obligations or reduces
                    PhoneBox&apos;s obligations, you may refuse the change and
                    cancel the contract without cost, penalty or cancellation
                    indemnity by sending PhoneBox a notice of cancellation no
                    later than 30 days after the change comes into force.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What if I do not agree with a change that PhoneBox makes
                      to the Agreement?
                    </strong>
                  </p>
                  <p>
                    If you do not agree with a change made by PhoneBox, you may
                    cancel the affected Services in accordance with Section 33.
                    There are no Cancellation Charges if you decide to cancel
                    because of a change that we make to the Agreement.
                  </p>
                </li>

                <TermConditionHeading2>
                  ACCOUNT, BILLING AND PAYMENT
                </TermConditionHeading2>

                <li>
                  <p>
                    <strong>How does PhoneBox bill me for the Services?</strong>
                  </p>
                  <p>
                    PhoneBox will invoice you monthly for recurring and one-time
                    charges (collectively, “Fees”). All Fees and applicable
                    taxes are invoiced on every 15th day of each month in your
                    billing cycle. In advance of the invoice date, you can
                    log-on to the My Account Portal on the Website and access a
                    statement of the Fees that will be due for the following
                    month. Newly added Services or changes to your Services may
                    result in pro-rated charges for a partial monthly billing
                    cycle. Service Agreements may also modify the billing terms
                    for certain Services.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      When must I pay my bill and how can I pay it?
                    </strong>
                  </p>
                  <p>
                    You must pay your bill by the 25th day of each month. You
                    can pay your bill online through your bank account, PayPal,
                    WeChat Pay, Alipay, electronic funds transfer, by cheque
                    (through the mail), with VISA, MasterCard or American
                    Express or you can arrange for a preauthorized automatic
                    payment via the My Account Portal. You confirm that you are
                    an authorized user of the credit card or bank account and
                    that it is valid and has not expired. If you are using a
                    method of payment that is subject to processing delays, you
                    must ensure that PhoneBox receives payments when due. You
                    must promptly advise PhoneBox if your credit card or bank
                    account information changes by contacting us at the
                    coordinates set out in Section 47 of these Service Terms.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What charges apply to late payments, rejected payments and
                      other account processing actions?
                    </strong>
                  </p>
                  <p>
                    If PhoneBox does not receive payment when due, you will be
                    subject to a late payment charge of 2.5% per month (accruing
                    on a daily basis and calculated and compounded monthly on
                    the outstanding amount - for a rate of 34.49% per year) or
                    the maximum rate allowable by law, whichever is lower (“Late
                    Payment Charges”) from the date of the first bill on which
                    it appears until the date we receive that amount in full.
                    You agree that we can charge any unpaid and outstanding
                    amount, including any Late Payment Charges and taxes, on
                    your account to your credit card, bank account or any other
                    payment method pre-authorized by you for payment of Fees.
                  </p>
                  <p>
                    <strong>Not applicable to Residents of Québec: </strong>
                    Administrative charges in the amount of $35.00 may be levied
                    for administration or account processing activities in
                    connection with your account, including as a result of:
                  </p>
                  <ul>
                    <li>a change of any Identifier.</li>
                    <li>
                      collection efforts due to non-payment or having a balance
                      over your credit limit, including unbilled usage and
                      pending charges, fees and adjustments;
                    </li>
                    <li>
                      returned or rejected payments due to non-sufficient funds
                      (“NSF”) or any other reason; and/or
                    </li>
                    <li>the restoral of Service</li>
                  </ul>
                  <br />
                  <p>
                    <strong>Applicable only to Residents of Québec:</strong>
                    Administrative charges in the amount of $35.00 may be levied
                    for administration or account processing activities in
                    connection with your account, including as a result of a
                    change of any Identifier. We will not levy administrative
                    charges due to NSF.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What if I dispute Fees on my invoice?</strong>
                  </p>
                  <p>
                    If you have any questions, disputes or discrepancies to
                    report regarding Fees, you must do so within thirty (30)
                    days of the invoice date. Failure to notify us within this
                    time period will constitute your acceptance of such Fees. We
                    will investigate disputes and if, in our sole discretion, we
                    determine that a portion of the Fees was incorrectly
                    charged, then we will reverse the disputed portion of the
                    Fees. You must pay the undisputed portion of the Fees in
                    accordance with Section 8.
                  </p>
                </li>
                <TermConditionHeading2>
                  YOUR RESPONSIBILITIES
                </TermConditionHeading2>
                <li>
                  <p>
                    <strong>
                      Does PhoneBox have any policies that apply to the
                      Services?
                    </strong>
                  </p>
                  <p>
                    Yes. From time to time, PhoneBox may establish policies,
                    rules and limits (collectively “Policies”) concerning the
                    use of the Services, Equipment and any products, content,
                    applications or services used in conjunction with the
                    Services or Equipment. The Policies are incorporated into
                    these Service Terms by reference, and will be available on
                    the Website. We will follow the process for amendments to
                    the Agreement, under Section 5 of these Service Terms, when
                    introducing new Policies or making changes to existing
                    Policies.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Are there any limits to my use of the Services?
                    </strong>
                  </p>
                  <p>
                    Yes. You agree to comply with and use the Services for your
                    own personal, family or household use, in accordance with
                    the Agreement and all applicable laws. You also agree not
                    to:
                  </p>
                  <ul>
                    <li>
                      Resell the Services, receive any charge or benefit for the
                      use of the Services; or
                    </li>
                    <li>Transfer your Services without our express consent.</li>
                  </ul>
                  <br />
                  <p>
                    You must also follow any Policies setting out acceptable use
                    guidelines for the Services and Equipment.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How can I be sure that PhoneBox has accurate contact
                      information for my account?
                    </strong>
                  </p>
                  <p>
                    You are responsible for keeping the contact and payment
                    information you provide to PhoneBox (including name, mailing
                    address, email address, address where the Services will be
                    provided to you), phone number, and any authorized users) up
                    to date. If this Agreement is cancelled, you will provide
                    PhoneBox with forwarding information for final invoices or
                    correspondence if your new contact information is different
                    from the information we have on file.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What am I responsible for if my PhoneBox account is
                      compromised?
                    </strong>
                  </p>
                  <p>
                    You must notify PhoneBox immediately using the contact
                    coordinates provided in Section 47 should you suspect
                    unauthorized use of the Services or if Equipment is lost or
                    stolen. You are responsible for payment of all Fees and
                    taxes charged to your account, whether authorized by you or
                    not, which is why it is so important to protect your account
                    and keep account information (including authorized users)
                    up-to-date.
                  </p>
                </li>
                <TermConditionHeading2>PHONEBOX SERVICES</TermConditionHeading2>
                <li>
                  <p>
                    <strong>Are there any warranties on the Services?</strong>
                  </p>
                  <strong>Not applicable to residents of Québec: </strong>
                  <p>
                    To the maximum extent permitted by law, PhoneBox Parties do
                    not guarantee or warrant the performance, availability,
                    coverage, uninterrupted use, security, pricing or operation
                    of the Services or any products, content, applications,
                    services, facilities, connections or networks used or
                    provided by us or third parties.
                  </p>
                  <p>
                    You bear the entire risk as to the use, access,
                    transmission, availability, reliability, timeliness,
                    quality, security and performance of the Services.
                  </p>
                  <p>
                    PhoneBox Parties do not make any express or implied
                    representations, warranties or conditions, including
                    warranties of title or non-infringement, or implied
                    warranties of merchantable quality or fitness for a
                    particular purpose, with regard to the Services or any
                    products, content, applications, services, facilities,
                    connections or networks used or provided by us or third
                    parties.
                  </p>
                  <p>
                    All representations, warranties and conditions of any kind,
                    express or implied, are excluded to the maximum extent
                    permitted by applicable law. To the maximum extent permitted
                    by applicable law, no advice or information, whether oral or
                    written, obtained by you from the PhoneBox Parties creates
                    any term, condition, representation or warranty not
                    expressly stated in the Agreement.
                  </p>
                  <strong>Applicable only to Residents of Québec:</strong>
                  <p>
                    To the maximum extent permitted by law, PhoneBox Parties do
                    not guarantee or warrant the performance, availability,
                    coverage, uninterrupted use, security, pricing or operation
                    of the Services (except as such warranties relate to
                    Equipment, in accordance with statutory warranties) or any
                    products, content, applications, services, facilities,
                    connections or networks used or provided by us or third
                    parties.
                  </p>
                  <p>
                    You bear the entire risk as to the use, access,
                    transmission, availability, reliability, timeliness,
                    quality, security and performance of the Services.
                  </p>
                  <p>
                    PhoneBox Parties do not make any express or implied
                    representations, warranties or conditions, including
                    warranties of title or non-infringement, or implied
                    warranties of merchantable quality or fitness for a
                    particular purpose, with regard to the Services or any
                    products, content, applications, services, facilities,
                    connections or networks used or provided by us or third
                    parties.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Does this mean that there may be circumstances when the
                      Services are not available?
                    </strong>
                  </p>
                  <p>
                    Unfortunately, yes. Performance and availability of the
                    Service depend on several factors, including access to
                    third-party providers and suppliers that PhoneBox does not
                    fully control.
                  </p>
                </li>
                <TermConditionHeading2>
                  PHONEBOX EQUIPMENT AND SOFTWARE
                </TermConditionHeading2>
                <li>
                  <p>
                    <strong>
                      Are there any limitations with respect to use of
                      Equipment?
                    </strong>
                  </p>
                  <p>
                    Yes. Except for Equipment that you have fully paid for, all
                    Equipment installed or provided by us remains our property
                    and you agree that:
                  </p>
                  <ul>
                    <li>You will take reasonable care of the Equipment;</li>
                    <li>
                      You may not sell, lease, mortgage, transfer, access,
                      assign or encumber the Equipment;
                    </li>
                    <li>
                      You may not relocate the Equipment without our knowledge
                      and permission;
                    </li>
                    <li>
                      If Equipment is lost, stolen, damaged, sold, leased,
                      mortgaged, transferred, assigned, encumbered or not
                      returned, you agree to pay us the undiscounted retail
                      value of Equipment, together with any costs incurred by us
                      in seeking possession of such Equipment; and
                    </li>
                    <li>
                      Upon termination of your Services, you will return
                      Equipment, accessories (e.g., cables) and ancillary
                      equipment (e.g, adapters) by mail, with adequate
                      protective packaging (e.g., bubble wrap), to the address
                      listed in Section 49 of the Service Terms.
                    </li>
                  </ul>
                  <br />
                </li>
                <li>
                  <p>
                    <strong>
                      What happens if there are problems with Equipment?
                    </strong>
                  </p>
                  <ol>
                    <li>
                      <strong>Equipment Warranty</strong>
                      <p>
                        For Equipment that you purchased from us, PhoneBox will
                        repair defects or replace defective Equipment at no cost
                        within a one-year period after purchase for new
                        equipment and within a three month period after purchase
                        for used and refurbished equipment. For Equipment that
                        you rent from us, PhoneBox will repair or replace
                        defective Equipment at no cost while the Equipment is
                        rented. The warranty on PhoneBox Equipment may not be
                        transferred.
                      </p>
                      <p>
                        PhoneBox shall inspect all Equipment submitted for
                        repair or replacement (whether purchased or rented) and
                        shall determine, in its sole discretion, whether the
                        Equipment is defective. In order to qualify for no cost
                        repair or replacement of defective Equipment, you must
                        provide the following to PhoneBox by mail at the address
                        identified in Section 47:
                      </p>
                      <ul>
                        <li>
                          The original receipt if Equipment was purchased;
                        </li>
                        <li>
                          The Equipment in its original packaging including all
                          protective packaging (e.g. bubble wrap) and materials
                          provided by the manufacturer; and
                        </li>
                        <li>
                          All accessories and ancillary equipment provided by
                          the manufacturer (e.g. power adapter, remote controls
                          and wires).
                        </li>
                      </ul>
                      <p>
                        PhoneBox reserves the right to charge you for any
                        Equipment or packaging that is not returned when you
                        request an exchange or repair. PhoneBox will specify the
                        amount of any such charges in the Service Agreement(s).
                        Applicable charges may change from time to time.
                      </p>
                      <p>
                        Except for shipping costs associated with repairs or
                        exchanges of Equipment that PhoneBox has confirmed to be
                        defective, you are responsible for all costs related to
                        shipping of Equipment.
                      </p>
                      <p>
                        For additional details about the process and timelines
                        for Equipment exchanges or repairs, please contact
                        PhoneBox at the coordinates set out in Section 47.
                      </p>
                    </li>
                    <li>
                      <strong>
                        Exclusion to PhoneBox&apos;s Equipment Warranty relating
                        to cosmetic damage
                      </strong>
                      <p>
                        PhoneBox&apos;s Equipment warranties does not apply to
                        cosmetic damages, including but not limited to
                        scratches, dents and broken plastic on ports unless
                        failure has occurred, in PhoneBox&apos;s sole
                        discretion, due to a defect in materials or workmsanship
                      </p>
                    </li>
                    <li>
                      <strong>
                        Exclusion to PhoneBox&apos;s Equipment Warranty
                        resulting from the Use of a Third Party Component or
                        Product
                      </strong>
                      <p>
                        PhoneBox&apos;s Equipment warranties does not apply to
                        damage caused by use with a third party component or
                        product.
                      </p>
                    </li>
                    <li>
                      <strong>
                        Exclusion to PhoneBox&apos;s Equipment Warranty relating
                        to External Clauses
                      </strong>
                      <p>
                        PhoneBox&apos;s Equipment warranties does not apply to
                        damage caused by accident, abuse, misuse, fire, liquid
                        contact, earthquake, lightning, power surge, or other
                        external cause.
                      </p>
                    </li>
                    <li>
                      <strong>
                        Exclusion to PhoneBox&apos;s Equipment Warranty relating
                        to Modified Equipment
                      </strong>
                      <p>
                        PhoneBox&apos;s Equipment warranties does not apply to
                        Equipment that has been modified to alter functionality
                        or capability.
                      </p>
                    </li>
                    <li>
                      <strong>
                        Exclusion to PhoneBox&apos;s Equipment Warranty relating
                        to Defects caused by normal wear
                      </strong>
                      <p>
                        PhoneBox&apos;s Equipment warranties does not apply to
                        defects caused by normal wear and tear or otherwise due
                        to the normal aging.
                      </p>
                    </li>
                    <li>
                      <strong>
                        Exclusion to PhoneBox&apos;s Equipment Warranty relating
                        to Stolen Equipment
                      </strong>
                      <p>
                        PhoneBox&apos;s Equipment warranties does not apply to
                        Equipment that has been stolen.
                      </p>
                    </li>
                  </ol>
                  <p></p>
                </li>
                <li>
                  <p>
                    <strong>
                      Do I need to grant PhoneBox access to Equipment?
                    </strong>
                  </p>
                  <p>
                    Yes. You agree to authorize us, our representatives and our
                    suppliers to enter or have access to your premises as
                    necessary at mutually agreed upon times to install,
                    maintain, inspect, repair, remove, replace, investigate,
                    protect, modify, upgrade, disconnect or improve the
                    operation of our services, the Equipment or our facilities
                    or networks. If any of your Services or accounts have been
                    terminated, then you authorize us and our representatives to
                    enter or have access to your premises to disconnect the
                    Services, as applicable. You must immediately notify us if
                    Equipment is lost, stolen or destroyed.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Will the Service ever require changes of Equipment?
                    </strong>
                  </p>
                  <p>
                    Yes. The Equipment, Equipment specifications and the
                    location of Equipment require changes, at our sole
                    discretion, from time to time. Unless otherwise specified by
                    us, you are solely responsible for updating or maintaining
                    your Equipment and software as necessary to meet such
                    requirements, and you may not be entitled to customer
                    support from us if you fail to do so.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Are there any limitations to the use of Software
                    </strong>
                  </p>
                  <p>
                    Yes. Software, including all related documentation
                    accompanying the Software (“Software Documents”), is for
                    your own non-commercial personal, family or household use
                    and may not be distributed, transferred or sold. All
                    Software and Software Documents remain our property or that
                    of our licensors or content providers, as applicable. You
                    agree to take reasonable steps to protect Software and
                    Software Documents from theft, loss or damage. You must
                    review and agree to any applicable end user licence
                    agreement of PhoneBox, our licensors or content providers.
                    Unless otherwise provided in the applicable end user licence
                    agreement, all end user licence agreements will terminate
                    upon termination of the applicable Service Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Are Software updates required?</strong>
                  </p>
                  <p>
                    Yes. From time to time, you may be required to update your
                    Software, including by way of automatic downloads. By
                    entering into the Agreement, you consent to receiving these
                    downloads. If you do not agree to receiving updates to
                    Software, you may not use the Service and your only option
                    is to terminate the Service Agreement for the Service(s)
                    that are subject to the software update.
                  </p>
                </li>
                <TermConditionHeading2>
                  PROPRIETARY RIGHTS
                </TermConditionHeading2>
                <li>
                  <p>
                    <strong>
                      Is the content that I access through the Services subject
                      to any intellectual property rights?
                    </strong>
                  </p>
                  <p>
                    Yes. You acknowledge that content including, but not limited
                    to text, software, music, sound, photographs, video,
                    graphics or other material accessed through the Services or
                    the Internet (collectively, the “Customer Accessed Content”)
                    is protected by applicable copyrights, trade-marks, patents,
                    trade secrets and/or other proprietary rights and laws.
                  </p>
                  <p>
                    Your license to use Customer Accessed Content, unless
                    otherwise permitted by applicable laws or by a valid licence
                    to use such content for other purposes, is limited to your
                    own personal, lawful, non-commercial use.
                  </p>
                  <p>
                    You further acknowledge that, except where expressly stated
                    otherwise, all Equipment, Software, content, documentation,
                    processes, designs, technologies, materials and all other
                    things comprising the Services are owned by PhoneBox, its
                    licensors or its suppliers and are protected by applicable
                    copyrights, trademarks, patents, trade secrets and/or other
                    proprietary rights and laws.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Do I have any rights to the content that I provide in
                      connection with the Services?
                    </strong>
                  </p>
                  <p>
                    Yes. PhoneBox does not claim ownership of information,
                    materials, software or other content (collectively, the
                    “Customer Provided Content”) that you post, upload, input,
                    provide, submit or otherwise transmit to PhoneBox or any
                    third party, using the Services. However, you agree that by
                    posting, uploading, inputting, providing, submitting or
                    otherwise transmitting the Customer Provided Content to
                    PhoneBox or any third party, using the Services, you have
                    thereby granted PhoneBox a royalty-free, non-exclusive
                    license to use, copy, distribute, transmit, display, edit,
                    delete, publish and translate such content to the extent
                    reasonably required by PhoneBox to provide the Services to
                    its customers or to ensure adherence to or enforce the terms
                    of this Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Who owns Identifiers used in connection with the Services?
                    </strong>
                  </p>
                  <p>
                    Except where otherwise specified by PhoneBox, Identifiers
                    remain the property of PhoneBox Parties at all times.
                  </p>
                </li>
                <TermConditionHeading2>
                  PRIVACY AND CONFIDENTIALITY OF YOUR INFORMATION
                </TermConditionHeading2>
                <li>
                  <p>
                    <strong>
                      How does PhoneBox protect my personal information?
                    </strong>
                  </p>
                  <p>
                    PhoneBox protects your personal information in a manner
                    consistent with its Policies and applicable laws.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Does PhoneBox perform credit checks or report credit
                      history?
                    </strong>
                  </p>
                  <p>
                    Yes, by entering into the Agreement you agree that PhoneBox
                    Parties may perform credit checks on you and obtain
                    information about your credit history from a credit
                    reporting agency or credit grantor to activate Services you
                    ordered, or to assist in collection efforts. PhoneBox
                    Parties may also disclose your PhoneBox credit history to
                    credit reporting agencies, credit grantors and/or
                    collections agencies.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Will PhoneBox send me commercial electronic messages?
                    </strong>
                  </p>
                  <p>
                    Yes. By entering into the Agreement, you are providing your
                    consent to receive commercial electronic messages from
                    PhoneBox. However, at any time, you may unsubscribe from
                    commercial electronic messages by using the unsubscribe
                    mechanism contained in such messages.
                  </p>
                </li>
                <TermConditionHeading2>
                  SERVICES AND RELATED LIMITATION OF LIABILITY
                </TermConditionHeading2>
                <li>
                  <p>
                    <strong>
                      What terms apply to the availability of 9-1-1 calling over
                      Services?
                    </strong>
                  </p>
                  <strong>
                    Is 9-1-1 service always available over Mobile Services?
                  </strong>
                  <p>
                    No. E9-1-1 is available in most of PhoneBox Mobile Service
                    coverage areas if the local emergency operator is capable of
                    receiving your GPS coordinates and location information of
                    the cellular tower enabling the call. This geographic
                    information assists emergency responders to dispatch the
                    appropriate emergency services to your approximate location.
                  </p>
                  <p>
                    9-1-1 calls over Mobile Services may be affected by
                    technical and environmental factors affecting signal
                    strength. 9-1-1 calling over Mobile Services can also be
                    affected by network outages.
                  </p>
                  <strong>
                    Is 9-1-1 service always available over Wi-Fi Calling?
                  </strong>
                  <p>
                    No. Over Wi-Fi Calling, we offer a form of 9-1-1 service
                    that is similar to traditional 9-1-1 (911) service but has
                    some important differences and limitations when compared
                    with enhanced 9-1-1 service (E911). With both traditional
                    9-1-1 and E911 service, your call is sent directly to the
                    nearest emergency response center. In addition, with E911
                    service, your callback number and address are visible to the
                    emergency response center call-taker.
                  </p>
                  <p>
                    With our Wi-Fi Calling 9-1-1 service, our third-party
                    operators route your call to the proper emergency services
                    agency anywhere in the country using the 9-1-1 network, but
                    your phone number and location are not visible to the
                    emergency response center call-taker. You should be prepared
                    to provide or confirm your address and call-back number with
                    the operator. Do not hang up unless told directly to do so
                    and if disconnected, dial 9-1-1 again.
                  </p>
                  <p>
                    Ensure that your location information in your mobile device
                    settings is current at all times. This location information
                    will be transmitted to the operator, who will then route the
                    call to the nearest emergency response center. In case you
                    are not able to speak during the 911 call, the call taker
                    will dispatch emergency response vehicles to the address
                    registered in your mobile device settings. Remember that you
                    need to update this information if you move your device to a
                    different location.
                  </p>
                  <p>
                    Wi-Fi Calling 9-1-1 service will not function in the event
                    of a power, broadband or Wi-Fi network outage or if your
                    Services are suspended or terminated. If you begin a Wi-Fi
                    Calling 9-1-1 call and move to an area where the Wi-Fi
                    signal is not available, your 9-1-1 call may be disconnected
                    and you will be required to place a new 9-1-1 call via
                    mobile wireless services.
                  </p>
                  <p>
                    You should inform any users of your mobile devices of the
                    important differences in, and limitations of, Wi-Fi Calling
                    9-1-1 service as compared with E911 service, as set out
                    above.
                  </p>
                  <strong>
                    Are there areas of Canada where 9-1-1 service is not
                    available?
                  </strong>
                  <p>
                    Yes. 9-1-1 service is not available in all areas of Canada.
                    If 9-1-1 service is not available in your area or you are
                    unsure of the availability of 9-1-1 services in your area,
                    please contact your local police, fire or
                    municipal/provincial/territorial emergency service
                    authorities.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How do PhoneBox Parties limit their liability for 9-1-1
                      calling over Mobile Services and Wi-Fi Calling 9-1-1
                      services?
                    </strong>
                  </p>
                  <p>
                    Depending on whether you are a resident of Québec or the
                    rest of Canada, PhoneBox Parties limit their liability for
                    9-1-1 calling over all PhoneBox Services as follows:
                  </p>
                  <p>
                    <strong>Not applicable to Residents of Québec: </strong>
                    You acknowledge and agree that, to the maximum extent
                    allowed by law, PhoneBox Parties will not be liable for any
                    injury, death or damage to persons or property, arising
                    directly or indirectly out of, or relating in any way to
                    9-1-1 calling from your Services and you agree to indemnify
                    and hold harmless the PhoneBox Parties for any liabilities,
                    claims, damages, losses and expenses, (including reasonable
                    legal fees and expenses) which you or anyone accessing or
                    attempting to access 9-1-1 calling from your Services may
                    suffer or incur, arising directly or indirectly out of or
                    relating to your or that person&apos;s failure to obtain
                    access to 9-1-1 emergency services.
                  </p>
                  <p>
                    <strong>Applicable only to Residents of Québec: </strong>
                    You acknowledge and agree that, except for damages resulting
                    from PhoneBox Parties&apos; own act(s), PhoneBox Parties
                    will not be liable for any injury, death or damage to
                    persons or property, arising directly or indirectly out of,
                    or relating in any way to 9-1-1 calling from your Services
                    and you agree to indemnify and hold harmless PhoneBox
                    Parties for any liabilities, claims, damages, losses and
                    expenses, (including reasonable legal fees and expenses)
                    which you or anyone accessing or attempting to access 9-1-1
                    calling from your Services may suffer or incur, arising
                    directly or indirectly out of or relating to your or that
                    person&apos;s failure to obtain access to 9-1-1 emergency
                    services. However, our liability is not limited by this
                    section in cases of deliberate fault, gross negligence on
                    our part or in cases of breach of contract where the breach
                    results from our gross negligence.
                  </p>
                </li>
                <TermConditionHeading2>
                  LIABILITY AND INDEMNIFICATION
                </TermConditionHeading2>
                <li>
                  <p>
                    <strong>
                      How do PhoneBox Parties limit their liability?
                    </strong>
                  </p>
                  <p>
                    You agree that, unless otherwise specifically set out in a
                    Service Agreement, to the maximum extent permitted by
                    applicable law, PhoneBox Parties&apos; liability for
                    negligence, breach of contract, tort, or other causes of
                    action, including fundamental breach, is limited to a
                    maximum amount equal to the greater of twenty ($20) or an
                    amount equal to the Fees payable during any service outage.
                  </p>
                  <p>
                    Other than the foregoing payment and to the maximum extent
                    permitted by applicable law, and except towards Residents of
                    Québec for damages resulting from a PhoneBox Parties&apos;
                    own act, PhoneBox Parties are not responsible to anyone for:
                  </p>
                  <strong>Not applicable to Residents of Québec:</strong>
                  <ul>
                    <li>
                      any direct, indirect, special, consequential, incidental,
                      economic or punitive damages (including loss of profit or
                      revenue, financial loss, loss of business opportunities,
                      loss, destruction or alteration of data, files or
                      software, breach of privacy or security property damage,
                      personal injury, death or any other foreseeable or
                      unforeseeable loss, however caused) resulting or relating
                      directly or indirectly to Services or any advertisements,
                      promotions or statements relating to any of the foregoing,
                      even if we were negligent or were advised of the
                      possibility of such damages;
                    </li>
                    <li>
                      The performance, availability, reliability, timeliness,
                      quality, coverage, uninterrupted use, security, pricing or
                      operation of Services;
                    </li>
                    <li>
                      Any error, inclusion or omission with respect Services;
                    </li>
                    <li>
                      The denial, restriction, blocking, disruption or
                      inaccessibility of any Services, public alerts or special
                      needs services, Equipment or Identifiers;
                    </li>
                    <li>
                      Any lost, stolen, damaged or expired Equipment,
                      Identifiers, passwords, codes, benefits, discounts,
                      rebates or credits;
                    </li>
                    <li>
                      Any acts or omissions of a telecommunications carrier
                      whose facilities are used to establish connections to
                      points that we do not serve; or
                    </li>
                    <li>
                      Any claims or damages resulting directly or indirectly
                      from any claim that the use, intended use or combination
                      of the service or any material transmitted through the
                      Services infringes the intellectual property, industrial,
                      contractual, privacy or other rights of a third party.
                    </li>
                  </ul>
                  <br />
                  <strong>Applicable only to Residents of Québec:</strong>
                  <ul>
                    <li>
                      Any damages (including loss of profit or revenue;
                      financial loss; loss of business opportunities; loss,
                      destruction or alteration of data, files or software;
                      breach of privacy or security; or property damage)
                      relating to Services;
                    </li>
                    <li>
                      The performance, availability, reliability, timeliness,
                      quality, coverage, uninterrupted use, security, pricing or
                      operation of Services;
                    </li>
                    <li>
                      Any error, inclusion or omission with respect Services;
                    </li>
                    <li>
                      The denial, restriction, blocking, disruption or
                      inaccessibility of any Services, public alerts or special
                      needs services, Equipment or Identifiers;
                    </li>
                    <li>
                      Any lost, stolen, damaged or expired Equipment,
                      Identifiers, passwords, codes, benefits, discounts,
                      rebates or credits;
                    </li>
                    <li>
                      Any error, omission or delay in connection with the
                      transfer of Identifiers to or from another
                      telecommunications service provider or any limitation
                      connected thereto;
                    </li>
                    <li>
                      Any acts or omissions of a telecommunications carrier
                      whose facilities are used to establish connections to
                      points that we do not serve; or
                    </li>
                    <li>
                      Any claims or damages resulting directly or indirectly
                      from any claim that the use, intended use or combination
                      of the Services or any material transmitted through the
                      Services infringes the intellectual property, industrial,
                      contractual, privacy or other rights of a third party.
                    </li>
                  </ul>
                  <br />
                  <strong>Not applicable to Residents of Québec:</strong>
                  <p>
                    These limits are in addition to any other limits on PhoneBox
                    Parties&apos; liability set out elsewhere in the Agreement
                    and apply to any act or omission of the PhoneBox Parties,
                    whether or not the act or omission would otherwise be a
                    cause of action in contract, tort or pursuant to any statute
                    or other doctrine of law.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Must I indemnify PhoneBox Parties with regards to the
                      Agreement?
                    </strong>
                  </p>
                  <p>
                    Yes. You agree to indemnify and hold harmless PhoneBox
                    Parties from all demands, claims, proceedings, losses,
                    damages, costs and expenses (including, without limitation,
                    reasonable legal fees and other litigation expenses)
                    incurred by or made against PhoneBox Parties, which result
                    from or relate to the Services, your use of the Services or
                    other matters related to this Agreement.
                  </p>
                </li>
                <TermConditionHeading2>
                  CANCELLING AND SUSPENDING SERVICES
                </TermConditionHeading2>
                <li>
                  <p>
                    <strong>When and how can I cancel Services?</strong>
                  </p>
                  <p>
                    You may cancel any or all of your Services and any
                    corresponding Service Agreement at any time by contacting
                    PhoneBox at the points of contact specified in these Service
                    Terms. Cancellation Charges, as defined in Section 37, may
                    apply.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Can Services be transferred to another provider?
                    </strong>
                  </p>
                  <p>
                    Some Services and Identifiers may be transferred to another
                    provider, pursuant to the terms of the corresponding Service
                    Agreement. Upon transfer-out of one or more Services and
                    Identifiers to another provider, the applicable Service
                    Agreements will be automatically cancelled. Cancellation
                    Charges, as defined in Section 37, may apply.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      When can PhoneBox cancel my Services, with notice?
                    </strong>
                  </p>
                  <strong>Not applicable to Residents of Québec: </strong>
                  <p>
                    PhoneBox may cancel any or all of your month-to-month
                    Services and corresponding Service Agreements upon no less
                    than thirty (30) days&apos; notice to you.
                  </p>
                  <p>
                    PhoneBox may only cancel Fixed Term Services if (1) we no
                    longer have the technical ability to provide those Services;
                    or (2) in accordance with Section 36.
                  </p>
                  <strong>Applicable only to Residents of Québec:</strong>
                  <p>
                    PhoneBox may cancel any or all of your month-to-month
                    Services and corresponding Service Agreements upon no less
                    than sixty (60) days&apos; notice to you at your billing
                    address.
                  </p>
                  <p>
                    PhoneBox may only cancel Fixed Term Services if: (1) we no
                    longer have the technical ability to provide those Services;
                    or (2) in accordance with Section 36.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      When can PhoneBox cancel or suspend my Services, without
                      notice?
                    </strong>
                  </p>
                  <strong>Applicable to all PhoneBox customers:</strong>
                  <p>
                    In addition to our rights to terminate your Services
                    pursuant to Section 35, PhoneBox may also restrict, block,
                    suspend, disconnect or terminate for cause any or all of
                    your Services, including 9-1-1 service, for cause, without
                    notice, if:
                  </p>
                  <ul>
                    <li>
                      You are in breach of the Agreement, including non-payment
                      of your charges or non-compliance with any Policies,
                      including the Acceptable Use Policy;
                    </li>
                    <li>
                      You exceed reasonable usage limits, as determined by us;
                    </li>
                    <li>
                      You have given us false, misleading or outdated
                      information by committing illegal or criminal acts,
                      impersonating other subscribers of ours or providing false
                      contact or payment information;
                    </li>
                    <li>
                      We reasonably suspect or determine that any of your
                      Services, Equipment and / or Identifiers are the subject
                      of fraudulent, unlawful or improper usage or usage that
                      adversely affects our operations or the use of our
                      services, facilities or networks by others;
                    </li>
                    <li>
                      You harass, threaten or abuse us or our employees or
                      agents;
                    </li>
                    <li>
                      You fraudulently or improperly seek to avoid payment to
                      us;
                    </li>
                  </ul>
                  <br />
                  <strong>
                    Applicable to Customers who are not Residents of Québec:
                  </strong>
                  <ul>
                    <li>
                      We need to install, maintain, inspect, test, repair,
                      remove, replace, investigate, protect, modify, upgrade or
                      improve the operation of the Services, the Equipment or
                      our facilities or networks; or
                    </li>
                    <li>
                      We reasonably believe that there is an emergency or
                      extreme circumstance that would warrant such action.
                    </li>
                  </ul>
                  <br />
                  <strong>Applicable only to Residents of Québec:</strong>
                  <p>
                    If you subscribe to a Fixed Term Service, then we may only
                    cancel your Service without notice if you are in default of
                    your obligations under the Agreement, pursuant to Sections
                    1604, 2126 and 2129 of the Civil Code of Québec.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What amounts must I pay if my Service(s) are cancelled?
                    </strong>
                  </p>
                  <p>
                    If you or PhoneBox cancels one or more of your Services you
                    must pay all outstanding fees (including any installation
                    fees for Services that have been installed but not
                    activated), taxes, and Late Payment Charges on your final
                    invoice for those cancelled Services.
                  </p>
                  <p>
                    For month-to-month Services, PhoneBox will provide a refund
                    for the cancelled portion of monthly service Fees that you
                    have paid in advance for Services. This refund will be
                    pro-rated based on the number of days left in the last
                    monthly billing cycle after cancellation.
                  </p>
                  <p>
                    If, in accordance with the Service Terms, you or PhoneBox
                    cancel a Service that is subject to a Fixed Term prior to
                    the expiry of the Term, then you must pay cancellation
                    charges (“Cancellation Charges”) specified in the Service
                    Agreement for those Services.
                  </p>
                  <p>
                    Services may also be subject to different cancellation and
                    refund terms, as set out in the applicable Service
                    Agreement(s).
                  </p>
                  <strong>Not Applicable to Residents of Québec:</strong>
                  <p>
                    To the maximum extent permitted by law, one-time activation
                    and installation fees are non-refundable. A re-stocking fee
                    may apply to Equipment that you return or Equipment that has
                    been ordered but not shipped. If Equipment that you have
                    purchased has been shipped prior to Service cancellation,
                    the fees for the Equipment are non-refundable. One-time
                    activation and installation fees and restocking/Equipment
                    fees are intended to recover costs and constitute a genuine
                    pre-estimate of liquidated damages in the event that a
                    Service is cancelled.
                  </p>
                  <p>
                    Not withstanding the foregoing, PhoneBox does not issue any
                    credits or refunds for cancellations for cause by PhoneBox,
                    pursuant to Section 36.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      How can I restore my Service(s) if they have been
                      restricted, blocked, suspended, disconnected or terminated
                      by PhoneBox?
                    </strong>
                  </p>
                  <p>
                    PhoneBox may, at its sole discretion, restore your Services,
                    with or without conditions that may include:
                  </p>
                  <ul>
                    <li>
                      Full repayment of outstanding amounts owing to PhoneBox;
                      or
                    </li>
                    <li>Changes to your Fees on an ongoing basis.</li>
                  </ul>
                  <br />
                </li>
                <li>
                  <p>
                    <strong>
                      What happens to credit balances on my final bill?
                    </strong>
                  </p>
                  <p>
                    If circumstances cause you to have a credit balance over $10
                    on your final bill, you will receive a cheque for this
                    amount in the mail. If the credit balance on your final bill
                    is less than $10, please contact us at the coordinates set
                    out in Section 47 to claim your refund.
                  </p>
                </li>
                <TermConditionHeading2>ADDITIONAL TERMS</TermConditionHeading2>
                <li>
                  <p>
                    <strong>
                      Do I have to meet certain criteria to enter into this
                      Agreement?
                    </strong>
                  </p>
                  <p>
                    Yes. By entering into this Agreement, you represent and
                    warrant that you have reached the age of majority in the
                    province or territory of Canada applicable to the Agreement
                    and that you possess the legal right and ability to enter
                    into this Agreement and use the Services in accordance with
                    this Agreement.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What is the process that applies to unresolved disputes
                      with PhoneBox?
                    </strong>
                  </p>
                  <p>
                    The process for billing disputes is described in Section 10
                    of the Service Terms. More generally, if you have a dispute
                    about Services, Software, Equipment, about this Agreement,
                    or about what we say outside this Agreement, then we want to
                    resolve the dispute quickly and fairly, and will work with
                    you in good faith to do so. If you have tried to work with
                    us but remain unsatisfied, then you have a right to escalate
                    the dispute. Any unresolved dispute may be referred to
                    voluntary single arbitrator arbitration. In such event, the
                    fees for the arbitrator shall be shared equally by the
                    parties.
                  </p>
                  <p>
                    If you are a consumer or small business with a monthly bill
                    under $2500, you may escalate certain types of complaints
                    externally with the Commission for Complaints for
                    Telecom-Television Services (“CCTS”) (www.ccts-cprst.ca,
                    1-888-221-1687). CCTS is the telecommunications consumer
                    agency designated by the Canadian Radio-television and
                    Telecommunications Commission (“CRTC”) to resolve certain
                    consumer and small business disputes about
                    telecommunications and television services. CCTS accepts
                    complaints relating to service delivery, contract disputes,
                    billing, credit management and unauthorized transfers of
                    service.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Which Court has jurisdiction over claims related to the
                      Agreement?
                    </strong>
                  </p>
                  <p>
                    By entering into the Agreement, except where the Canadian
                    Radio-television and Telecommunications Commission, the CCTS
                    or an arbitrator has jurisdiction, you consent to the
                    exclusive jurisdiction of courts located in the province in
                    which your billing address is located. However, if your
                    billing address is outside of Canada, you submit to the
                    jurisdiction of the province or territory in which the
                    Services are delivered.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>What laws apply to this Agreement?</strong>
                  </p>
                  <p>
                    The Agreement is governed exclusively by and construed in
                    accordance with the laws of the province or territory in
                    which your billing address is located, without regard to the
                    principles of conflict of laws, but if your billing address
                    is outside of Canada, the Agreement is governed exclusively
                    by the laws of the province or territory in which the
                    Services are delivered.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      What if parts of this Agreement become unenforceable?
                    </strong>
                  </p>
                  <p>
                    If any part of this Agreement becomes outdated, prohibited
                    or unenforceable, the remaining terms will continue to apply
                    to you and PhoneBox. Even if PhoneBox decides not to enforce
                    any part of this Agreement for any period of time, the term
                    still remains valid and PhoneBox can enforce it in the
                    future.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Can this Agreement be assigned or transferred?
                    </strong>
                  </p>
                  <p>
                    PhoneBox may transfer or assign all or part of this Contract
                    (including any rights in accounts receivable) at any time
                    without prior notice or your consent. You may not transfer
                    or assign this Agreement, your account(s) or the Service
                    without PhoneBox&apos;s prior written consent.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      Do any terms or obligations of this Agreement apply after
                      the cancellation or expiry of the Agreement?
                    </strong>
                  </p>
                  <p>
                    Yes. The cancellation, transfer or expiry of a Service
                    Agreement or the Agreement shall not release you or us from
                    any obligation which has accrued prior to that event,
                    including but not limited to the obligation to pay any
                    outstanding amounts owed for Services.
                  </p>
                </li>
                <li>
                  <p><strong>The Wireless Code:</strong></p>
                  <p>The CRTC's Wireless Code, implemented in December 2013 and updated in 2017, sets nationwide standards for wireless service providers in Canada. PhoneBox was an early advocate of this initiative, committed to offering our valued customers more choice, flexibility, and control over their wireless services.
                  </p>
                  <strong>Who Benefits from the Code:
                  </strong>
                  <p>The Code is designed to protect consumers and small business customers, ensuring consistent rights and standards across all wireless providers in Canada.
                  </p>
                  <strong>What the Code Means for You:
                  </strong>
                  <p>The Code covers several important aspects to enhance your experience:
                  </p>
                  <ul>
                    <li>Critical Information Summary: Navigating your PhoneBox wireless account is now more straightforward. With every wireless service agreement, we provide a critical information summary that outlines the key points of your contract. You can access this summary by logging into your online account.</li>
                    <li>Contract Delivery: Postpaid customers have the option to receive their contracts either in paper form or via email. You can also request a free copy of your contract at any time.</li>
                    <li>Domestic Data Overage Limit: Each line on an account is subject to a $50 domestic data overage limit, ensuring you don't incur unexpected charges.</li>
                    <li>Flexible Data Plans: Any additional data charges beyond the base monthly fee will count towards the $50 domestic data overage limit.</li>
                  </ul>
                  <br />
                  <p>For more detailed information, please visit the <a href="https://crtc.gc.ca/eng/phone/mobile/codesimpl.htm" target="_blank">CRTC's website</a>.</p>
                </li>
              </ol>
              <AcceptableUsePolicyHeading>
                Acceptable Use Policy
              </AcceptableUsePolicyHeading>
              <TermConditionHeading2>INTRODUCTION</TermConditionHeading2>
              <p>
                Connex Global Communications Inc. o/a PhoneBox (
                <strong>“PhoneBox”</strong>) is committed to being the best
                possible network citizen. This means playing our part to
                maximize the usefulness, functionality and enjoyment of the
                Internet. In order to fulfill these goals, we require every
                person who uses the Services, Equipment and / or the Website, as
                defined in the PhoneBox Terms and Conditions of Service (
                <strong>“Service Terms”</strong>), to comply with the terms of
                this Acceptable Use Policy (<strong>“AUP”</strong>).
              </p>
              <p>
                Throughout the AUP, all capitalized terms have the meaning
                defined in the Service Terms. For additional clarity:
              </p>
              <ul>
                <li>
                  <strong>“I”</strong>, <strong>“me”</strong>,{" "}
                  <strong>“you”</strong>, <strong>“your”</strong> and{" "}
                  <strong>“yours”</strong> refer to you but also to persons that
                  you authorize to use the Service or act as your agent with
                  regard to the Service;
                </li>
                <li>
                  <strong>“us”</strong>, <strong>“we”</strong>,{" "}
                  <strong>“our”</strong> or <strong>“ourselves”</strong> means
                  PhoneBox;
                </li>
              </ul>
              <p>
                If you do not agree to be bound by this AUP, as amended from
                time to time, you must immediately stop using the Services,
                Equipment and / or Website and notify PhoneBox that you are
                cancelling the Services, pursuant to the Service Terms and any
                applicable Service Agreements.
              </p>
              <TermConditionHeading2>
                PROHIBITED ACTIVITIES
              </TermConditionHeading2>
              <p>
                When using the Services, Equipment and / or Website, you are
                prohibited from assisting others, using, enabling, facilitating,
                or permitting the use of any Services, Equipment and / or
                Website for:
              </p>
              <ol type="a">
                <li>
                  <p>
                    uses or purposes contrary to applicable laws and
                    regulations;
                  </p>
                </li>
                <li>
                  <p>
                    any illegal or criminal purpose including but not limited to
                    acts relating to: harassment/stalking, child pornography,
                    obscenity, uttering threats, communicating hatred, fraud,
                    hacking or unauthorized use of a computer and mischief
                    relating to data;
                  </p>
                </li>
                <li>
                  <p>
                    uses that give rise to civil liability or that violate the
                    rights of PhoneBox or any third party, including, but not
                    limited to violations of rights relating to: copyright
                    infringement, trademark infringement, patent infringement,
                    misappropriation of trade secrets and defamation;
                  </p>
                </li>
                <li>
                  <p>
                    invading, breaching or undermining the privacy of other
                    Internet users;
                  </p>
                </li>
                <li>
                  <p>
                    using harassing or abusive language or actions, whether
                    verbal, written or otherwise, with: our employees,
                    suppliers, agents, representatives and any Internet users or
                    groups;
                  </p>
                </li>
                <li>
                  <p>
                    restricting or inhibiting any other user from using or
                    enjoying the Internet;
                  </p>
                </li>
                <li>
                  <p>
                    impersonating other subscribers of ours and engaging in
                    fraudulent activities, including but not limited to, forging
                    anyone&apos;s digital or manual signature;
                  </p>
                </li>
                <li>
                  <p>
                    reproducing, distributing, selling, reselling or otherwise
                    exploiting the Services or content we provide or which you
                    obtain through the Services for any commercial purposes
                    without our express written consent;
                  </p>
                </li>
                <li>
                  <p>
                    copying, distributing, sub-licensing or otherwise making
                    available any software or content we provide or make
                    available to you or which you obtain through the Services,
                    except as authorized by us;
                  </p>
                </li>
                <li>
                  <p>
                    transmitting content including, without limitation,
                    unsolicited messages (i.e. Spam) that, in our sole judgment,
                    causes interference with, or disruption of, PhoneBox&apos;s
                    network or the Internet or the use or enjoyment of the
                    Internet by other end-users, whether intentional or
                    unintentional;
                  </p>
                </li>
                <li>
                  <p>
                    interfering or attempting to interfere with computer
                    networking or telecommunications systems and/or service to
                    or from any Internet user, host, server or network,
                    including but not limited to compromising the security of,
                    or tampering with, system resources or accounts, denial of
                    service attacks, overloading a service, improper seizure and
                    abuse of operator privileges (“hacking”) or attempting to
                    “crash” a host, disrupting sessions of other Internet users
                    or consuming excessive amounts of central processing unit
                    time, memory or disk space;
                  </p>
                </li>
                <li>
                  <p>
                    consuming excessive amounts of bandwidth in a manner that
                    interferes with computer networking or telecommunications
                    systems and/or service to or from any Internet user, host,
                    server or network;
                  </p>
                </li>
                <li>
                  <p>
                    accessing or attempting to access any Internet host,
                    computer, software or data belonging to any other person
                    without that person&apos;s authorization, or using any tools
                    designed to facilitate such access, such as “packet
                    sniffers”;
                  </p>
                </li>
                <li>
                  <p>
                    transmitting, posting, publishing, disseminating, receiving,
                    retrieving, storing or otherwise reproducing, distributing
                    or providing access to any files, programs or information
                    (including, without limitations, guessing programs, cracking
                    tools or network probing tools) designed to assist users in
                    compromising the security of the Service, the PhoneBox
                    network or telecommunications services;
                  </p>
                </li>
                <li>
                  <p>
                    transmitting, posting, publishing, disseminating, receiving,
                    retrieving, storing or otherwise reproducing, distributing
                    or providing access to any files, programs or information
                    that is harmful or disruptive (including, without
                    limitations, viruses, “cancelbots”, “trojan horses” and
                    “worms”) even if the purpose is to test or analyze a
                    host&apos;s security mechanisms;{" "}
                  </p>
                </li>
                <li>
                  <p>
                    attempting to receive any Services without paying the
                    applicable Fees;
                  </p>
                </li>
                <li>
                  <p>modifying or disassembling Services or Equipment;</p>
                </li>
                <li>
                  <p>
                    changing any Identifier issued by PhoneBox without
                    PhoneBox&apos;s consent or otherwise forging or manipulating
                    Identifiers for a malicious or improper purpose or to
                    disguise the origin of any content transmitted through the
                    Services;
                  </p>
                </li>
                <li>
                  <p>
                    using an Internet host&apos;s resources in a manner that is
                    not authorized by its administrators. This includes mail
                    relaying, transmitting chain letters, make-money-fast or
                    pyramid style schemes of any sort; and
                  </p>
                </li>
                <li>
                  <p>
                    unless you have purchased Services that are specifically
                    designed and authorized to support such functionalities,
                    operating a server in connection with the Services including
                    but not limited to mail, news, file, gopher, telnet, chat,
                    web, or host configuration servers, multimedia streamers, or
                    multi-user interactive forums.
                  </p>
                </li>
              </ol>
              <TermConditionHeading2>GENERAL TERMS</TermConditionHeading2>
              <p>
                <strong>
                  How does PhoneBox respond to violations of the AUP?
                </strong>
              </p>
              <p>
                In accordance with the Service Terms, PhoneBox can restrict,
                block, suspend, disconnect or terminate any Services, or the
                Agreement for cause if the AUP is violated. PhoneBox may also
                take any other measures that are necessary to address a
                violation of the AUP to the maximum extent permitted by law
                including, without limitation, temporary or permanent removal of
                content, cancellation of newsgroup posts and/or filtering of
                Internet transmissions.
              </p>
              <p>
                PhoneBox will assess the circumstances giving rise to the breach
                before making a determination.
              </p>
              <p>
                <strong>Will PhoneBox change the AUP?</strong>
              </p>
              <p>
                We may amend this AUP in accordance with Section 11 of the
                Service Terms.
              </p>
              <p>
                <strong>
                  Where should complaints relating to breaches of the AUP be
                  directed?
                </strong>
              </p>
              <p>
                Please direct any complaints relating to violations of this AUP
                by email to services@gophonebox.com or by phone at
                1-855-886-0505.
              </p>
              <TermConditionHeading2>HOW TO CONTACT US</TermConditionHeading2>
              <ol>
                <li>
                  <p>
                    <strong>How do I contact PhoneBox?</strong>
                  </p>
                  <p>
                    To contact PhoneBox for any reason, including providing
                    notices to PhoneBox pursuant to this Agreement, you may
                    reach us:
                  </p>
                  <ul>
                    <li>By Phone: +1-855-886-0505</li>
                    <li>By email: services@gophonebox.com</li>
                  </ul>
                  <br />
                </li>
                <li>
                  <p>
                    <strong>
                      How do I contact PhoneBox for technical support?
                    </strong>
                  </p>
                  <p>
                    Unless otherwise specified in a Service Agreement, PhoneBox
                    offers technical support for its Services between 9:30 AM
                    and 6:00 PM (Pacific Standard Time / Pacific Daylight Time)
                    from Monday to Friday and between 10:00 AM to 6:00 PM
                    (Pacific Standard Time / Pacific Daylight Time) on Saturdays
                    and Sundays, with the exception of provincial and federal
                    statutory holidays. You may contact PhoneBox for technical
                    support via the contact information set out above in Section
                    47.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Where do I return Equipment?</strong>
                  </p>
                  <p>
                    You can return Equipment by mail or in person to the
                    following addresses:
                  </p>
                  <p>
                    1240 Bay St. #305
                    <br />
                    Toronto, ON
                    <br />
                    M5R 2A7
                    <br />
                    <br />
                    658 Seymour St.
                    <br />
                    Vancouver, BC <br />
                    V6B 3K4
                  </p>
                </li>
              </ol>
            </>
          )}
        </TermDetail>
      </MyContainer>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default TermOfService;
